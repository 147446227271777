import {
  EmbedIcon,
  MenuAdServerIcon,
  MenuCraftIcon,
  MenuExperimentsIcon,
  MenuOnboardingIcon,
  MenuOrganizationsIcon,
  MenuSettingsIcon,
  PageIcon,
} from '@ftbpro/mm-admin-assets';
import { EXPERIMENTS_ROUTES } from 'containers/experiments/experiments.constants';
import { ADS_TXT_ROUTES } from 'containers/adsTxt/adsTxt.constants';
import { MONETIZATION_ROUTE_ROUTES } from 'containers/commercial/monetization/monetizationNavigator';
import { UDS_ROUTE_ROUTES } from 'containers/commercial/uds/monetizationNavigator';
import { ALERTS_ROUTES } from 'containers/alerts/alerts.constants';
import SitePolicyIcon from '../../ui/assets/menu/icon-menu-sitepolicy.inline.svg';
import { MARKETPLACE_ROUTES } from '../../containers/marketplace/marketplaceNavigator';
import { GlobalRole, HQRole } from '../../core/store/user/roles.constants.hq';
import { NavigationItemData } from '../types';
import VmsIcon from '../../ui/assets/menu/icon-menu-vms.inline.svg';
import { ADMINISTRATION_ROUTES } from '../../containers/administration/administration.constants';
import { VMS_ROUTES_SCHEMA } from '../../containers/vms/vms.constants';
import { CRAFT_ROUTES } from '../../containers/craftHq/craft.constants';
import { ORGANIZATION_ROUTES } from '../../containers/organizationHq/organization.constants';
import { ANALYTICS_ROUTES } from '../../containers/reports/reports.constants';
import { ONBOARDING_ROUTES } from '../../containers/creatorsOnboarding/onboarding.constants';
import { AD_SERVER_ROUTES } from '../../containers/adServer/adServerNavigator';
import { SEO_ROUTES } from '../../containers/seo/seo.constants';

const {
  AdServerManager,
  AdServerViewer,
  AdsTxtAdmin,
  MarketplaceAdmin,
  MarketplaceEditor,
  MarketplaceViewer,
  SeoManager,
  SitePolicyManager,
  SitePolicyViewer,
  ExternalUserAdmin,
  InternalUserAdmin,
  ExternalAuditViewer,
  InternalAuditViewer,
  SetupManager,
  InjectionsManager,
  VMSAdmin,
  ExperimentsManager,
  OnboardingAdmin,
  VersionManagement,
  PartnerAccountManager,
  PartnerManagementAdmin,
  VMSSspMaker,
  VMSAbTest,
  QueryBuilderViewer,
  AnalyticsAdmin,
  RoleAdmin,
  Targeting,
  DataAlertsManager,
  AnalyticsDisplayMonetizationReportsBuilderTenant,
  MonetizationAdmin,
  UpsmmDashboards,
} = HQRole;

export const AvailableNavigationItems: NavigationItemData[] = [
  {
    title: 'Ad Server',
    icon: MenuAdServerIcon,
    requiredPermissions: [AdServerManager, AdServerViewer],
    subItems: [
      {
        title: 'Monitoring',
        url: AD_SERVER_ROUTES.MONITORING,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Advertisers',
        url: AD_SERVER_ROUTES.ADVERTISERS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Campaigns',
        url: AD_SERVER_ROUTES.CAMPAIGNS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Line Items',
        url: AD_SERVER_ROUTES.LINE_ITEMS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Creatives',
        url: AD_SERVER_ROUTES.CREATIVES,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Ad Units',
        url: AD_SERVER_ROUTES.AD_UNITS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Targeting Presets',
        url: AD_SERVER_ROUTES.TARGETING_PRESETS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Forecasting',
        url: AD_SERVER_ROUTES.FORECASTING,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Publishers',
        url: AD_SERVER_ROUTES.PUBLISHERS,
        requiredPermissions: [AdServerManager, AdServerViewer],
      },
      {
        title: 'Tools',
        requiredPermissions: [AdServerManager, AdServerViewer],
        subItems: [
          {
            title: 'Live Line Items',
            url: AD_SERVER_ROUTES.LIVE_LINE_ITEMS,
            requiredPermissions: [AdServerManager, AdServerViewer],
          },
        ],
      },
    ],
  },
  {
    title: 'Ads.txt',
    icon: PageIcon,
    requiredPermissions: [AdsTxtAdmin],
    url: ADS_TXT_ROUTES.BASE,
  },
  {
    title: 'VMS',
    icon: VmsIcon,
    requiredPermissions: [VersionManagement, InjectionsManager, VMSAdmin, VMSSspMaker, Targeting],
    subItems: [
      {
        title: 'Playlists',
        url: VMS_ROUTES_SCHEMA.PLAYLISTS,
        requiredPermissions: [VMSAdmin],
      },
      {
        title: 'Players',
        url: VMS_ROUTES_SCHEMA.PLAYERS,
        requiredPermissions: [VMSAdmin],
      },
      {
        title: 'Injections',
        url: VMS_ROUTES_SCHEMA.INJECTIONS,
        requiredPermissions: [InjectionsManager],
      },
      {
        title: 'Targeting',
        url: VMS_ROUTES_SCHEMA.TARGETING,
        requiredPermissions: [Targeting],
      },
      {
        title: 'SSP Maker',
        url: VMS_ROUTES_SCHEMA.SSP_MAKER_GROUPS,
        requiredPermissions: [VMSSspMaker],
      },
      {
        title: 'Version management',
        url: VMS_ROUTES_SCHEMA.VERSION_MANAGEMENT,
        requiredPermissions: [VersionManagement],
      },
      {
        title: 'A/B Testing',
        url: VMS_ROUTES_SCHEMA.AB_TEST,
        requiredPermissions: [VMSAbTest],
      },
      // {
      //   title: 'Video Builder',
      //   url: VMS_ROUTES_SCHEMA.VIDEO_BUILDER,
      //   requiredPermissions: [VMSAdmin],
      // },
    ],
  },
  {
    title: 'Marketplace',
    icon: EmbedIcon,
    requiredPermissions: [
      MarketplaceAdmin,
      MarketplaceEditor,
      MarketplaceViewer,
    ],
    subItems: [
      {
        title: 'Publishers',
        url: MARKETPLACE_ROUTES.PUBLISHERS,
        requiredPermissions: [
          MarketplaceAdmin,
          MarketplaceViewer,
        ],
      },
    ],
  },
  {
    title: 'Monetization',
    icon: SitePolicyIcon,
    requiredPermissions: [SitePolicyManager, SitePolicyViewer],
    subItems: [
      {
        title: 'Bidders',
        url: MONETIZATION_ROUTE_ROUTES.BIDDERS,
        requiredPermissions: [SitePolicyManager, SitePolicyViewer],
      },
      {
        title: 'Demand Configuration',
        url: MONETIZATION_ROUTE_ROUTES.DEMAND_CONFIGURATION,
        requiredPermissions: [SitePolicyManager, SitePolicyViewer],
      },
      {
        title: 'Policies',
        url: MONETIZATION_ROUTE_ROUTES.POLICIES,
        requiredPermissions: [SitePolicyManager, SitePolicyViewer],
      },
      {
        title: 'Targeting',
        url: MONETIZATION_ROUTE_ROUTES.TARGETING,
        requiredPermissions: [SitePolicyManager, SitePolicyViewer],
      },
      {
        title: 'Publishers',
        url: MONETIZATION_ROUTE_ROUTES.PUBLISHERS,
        requiredPermissions: [SitePolicyManager, SitePolicyViewer],
      },
    ],
  },
  {
    title: 'UDS',
    icon: SitePolicyIcon,
    requiredPermissions: [MonetizationAdmin],
    subItems: [
      {
        title: 'Bidders',
        url: UDS_ROUTE_ROUTES.BIDDERS,
        requiredPermissions: [MonetizationAdmin],
      },
      {
        title: 'Demand Configuration',
        url: UDS_ROUTE_ROUTES.DEMAND_CONFIGURATION,
        requiredPermissions: [MonetizationAdmin],
      },
      {
        title: 'Policies',
        url: UDS_ROUTE_ROUTES.POLICIES,
        requiredPermissions: [MonetizationAdmin],
      },
      {
        title: 'Targeting',
        url: UDS_ROUTE_ROUTES.TARGETING,
        requiredPermissions: [MonetizationAdmin],
      },
    ],
  },
  {
    title: 'Administration',
    icon: MenuSettingsIcon,
    requiredPermissions: [ExternalUserAdmin, InternalUserAdmin, ExternalAuditViewer, InternalAuditViewer, GlobalRole.MegaAdmin],
    subItems: [
      {
        title: 'HQ Users',
        url: ADMINISTRATION_ROUTES.HQ_USERS,
        requiredPermissions: [InternalUserAdmin, GlobalRole.MegaAdmin],
      },
      {
        title: 'Voltax Users',
        url: ADMINISTRATION_ROUTES.VOLTAX_USERS,
        requiredPermissions: [ExternalUserAdmin, GlobalRole.MegaAdmin],
      },
      {
        title: 'HQ Audit',
        url: ADMINISTRATION_ROUTES.HQ_AUDIT,
        requiredPermissions: [InternalAuditViewer],
      },
      {
        title: 'Voltax Audit',
        url: ADMINISTRATION_ROUTES.VOLTAX_AUDIT,
        requiredPermissions: [ExternalAuditViewer],
      },
      {
        title: 'HQ Roles',
        url: ADMINISTRATION_ROUTES.HQ_ROLES,
        requiredPermissions: [RoleAdmin],
      },
      {
        title: 'Voltax Roles',
        url: ADMINISTRATION_ROUTES.VOLTAX_ROLES,
        requiredPermissions: [RoleAdmin],
      },
    ],
  },
  {
    title: 'Analytics',
    icon: VmsIcon,
    requiredPermissions: [],
    subItems: [
      {
        title: 'Explore',
        url: ANALYTICS_ROUTES.QUERY_BUILDER,
        requiredPermissions: [QueryBuilderViewer],
      },
      {
        title: 'Voltax Analytics Users',
        url: ANALYTICS_ROUTES.ANALYTICS_USERS,
        requiredPermissions: [AnalyticsAdmin],
      },
      {
        title: 'HQ Analytics Users',
        url: ANALYTICS_ROUTES.HQ_ANALYTICS_USERS,
        requiredPermissions: [AnalyticsAdmin],
      },
      {
        title: 'Data Sources',
        url: ANALYTICS_ROUTES.DATA_SOURCES_MANAGEMENT,
        requiredPermissions: [AnalyticsAdmin],
      },
      {
        title: 'MM Dashboards',
        url: ANALYTICS_ROUTES.MM_DASHBOARDS,
        requiredPermissions: [UpsmmDashboards],
      },
      {
        title: 'Reports',
        url: ANALYTICS_ROUTES.REPORTS,
        requiredPermissions: [AnalyticsDisplayMonetizationReportsBuilderTenant],
      },
    ],
  },
  {
    title: 'Alerts',
    requiredPermissions: [AnalyticsAdmin],
    subItems: [
      {
        title: 'Monitors',
        url: ALERTS_ROUTES.ALERTS_MONITORS,
        requiredPermissions: [DataAlertsManager],
      },
      {
        title: 'Queries',
        url: ALERTS_ROUTES.ALERTS_QUERIES,
        requiredPermissions: [DataAlertsManager],
      },
    ],
  },
  {
    title: 'Craft',
    icon: MenuCraftIcon,
    requiredPermissions: [SetupManager],
    subItems: [
      {
        title: 'External Scripts',
        url: CRAFT_ROUTES.EXTERNAL_SCRIPTS,
        requiredPermissions: [SetupManager],
      },
    ],
  },
  {
    title: 'SEO',
    requiredPermissions: [SetupManager],
    subItems: [
      {
        title: 'Redirections',
        url: SEO_ROUTES.REDIRECTIONS,
        requiredPermissions: [SeoManager],
      },
      {
        title: 'Bulk Redirections',
        url: SEO_ROUTES.BULK_REDIRECTIONS,
        requiredPermissions: [SeoManager],
      },
      {
        title: 'Bulk Delete',
        url: SEO_ROUTES.BULK_DELETE,
        requiredPermissions: [SeoManager],
      },
    ],
  },
  {
    title: 'Organizations',
    icon: MenuOrganizationsIcon,
    requiredPermissions: [],
    subItems: [{
      title: 'Setup',
      url: ORGANIZATION_ROUTES.SETUP,
      requiredPermissions: [SetupManager],
    },
    {
      title: 'Partners',
      url: ORGANIZATION_ROUTES.PARTNERS,
      requiredPermissions: [PartnerAccountManager, PartnerManagementAdmin],
    },
    ],
  },
  {
    title: 'Onboarding',
    icon: MenuOnboardingIcon,
    requiredPermissions: [OnboardingAdmin],
    subItems: [{
      title: 'Proposals',
      url: ONBOARDING_ROUTES.PROPOSALS,
      requiredPermissions: [OnboardingAdmin],
    }],
  },
  {
    title: 'Experiments',
    icon: MenuExperimentsIcon,
    requiredPermissions: [ExperimentsManager],
    url: EXPERIMENTS_ROUTES.BASE,
  },
];
